import React from "react"
import appLogo from "../../assets/app_logo.svg";
import appBanner from "../../assets/app_framed_tiny.png";
import googlePlayBadge from "../../assets/google-play-badge.png";

export default class Home extends React.Component {

  render() {
    return (
      <div className="App">
        <header className="App-header">

          <img src={appLogo} className="App-logo" alt="logo"/>
          <h1>Font Manager</h1>

          <img src={appBanner} className="appPreview" alt="App Preview"/>

          <a href={"https://play.google.com/store/apps/details?id=com.deishelon.emuifontmanager"} target={"_black"} >
            <img src={googlePlayBadge} className="googlePlayBanner" alt="Google Play Download"/>
          </a>
        </header>
      </div>
    );
  }
}